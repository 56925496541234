<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :rules="rules" ref="form" :model="form" label-width="200px" style="width:600px">
        <el-form-item prop="app_code" label="schema终端编号">
          <el-select v-model="form.app_code">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="platform" label="平台标识">
          <el-select v-model="form.platform">
            <el-option label="安卓" :value="1"></el-option>
            <el-option label="IOS" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="version_code" label="最小APP版本号">
            <el-input v-model="form.version_code"></el-input>
        </el-form-item>
        <el-form-item prop="status" label="状态">
            <el-radio-group v-model="form.status">
                <el-radio-button :label="1">撤销</el-radio-button>
                <el-radio-button :label="2">测试</el-radio-button>
                <el-radio-button :label="3">发布</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <h4 style="margin-bottom:10px;">| 设置多语言版本日志</h4>
        <el-form-item
          v-for="(item,key) in logsitem"
          :label="'语言类型'"
          :key="key"
        >
          <el-select v-model="item.code" placeholder="语言类型">
            <el-option
              v-for="ele in langueList"
              :key="ele.code"
              :label="ele.name"
              :value="ele.code">
            </el-option>
          </el-select>
          <el-input
            style="margin-top:10px"
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="item.log">
          </el-input>
          <el-button style="position: absolute;margin-left: 10px;" size="mini" type="warning" @click.prevent="removeLanguage(item)">删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="addLanguage">新增多语言日志</el-button>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="Validate(onSubmit)">提交</el-button>
        </el-form-item>
    </el-form>
    
  </div>
</template>

<script type="text/ecmascript-6">
// import util from '../../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      form: {
        code: '',
        app_code: '',
        platform: '',
        version_code: '',
        status: 1,
        log: '',
      },
      rules: {
        app_code: [{ required: true, message: '请输入schema终端编号', trigger: 'blur' }],
        md5: [{ required: true, message: '请输入版本包md5信息', trigger: 'blur' }],
        platform: [{ required: true, message: '请输入平台标识', trigger: 'blur' }],
        version_code: [{ required: true, message: '请输入最小APP版本号', trigger: 'blur' }],
      },
      contentCode: [],
      soft_code: '',
      applist: [],
      logsitem: [],
      langueList: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetLanguage () {
      this.api.LanguageList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.langueList = res.data.data.data
          return
        }
        this.$message.error('语言获取失败' + res.data.msg)
      })
    },
    GetClientcode () {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          this.GetLanguage()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.ForceUpgradeDetail({
        code: this.soft_code
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          let logs = JSON.parse(res.data.data.log)
          for (var key in logs) {
            this.logsitem.push({code: key,log: logs[key]})
          }
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    goback (){
      this.$router.push('/iot/version/forceupgrade')
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    onSubmit (){
      let logs = {}
      this.logsitem.forEach(item => {
        if (item.code) {
          logs[item.code] = item.log
        }
      })
      this.form.log = JSON.stringify(logs)
      this.api.ForceUpgradUpdate({
        code: this.form.code,
        app_code: this.form.app_code,
        platform: this.form.platform,
        version_code: this.form.version_code,
        status: this.form.status,
        log: this.form.log,
      }).then(res => {
        if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '提交成功!'
            })
            return
          }
          this.$message.error('提交失败' + res.data.msg)
      })
    },
    addLanguage() {
      this.logsitem.push({
        name: '',
        code: ''
      });
    },
    removeLanguage (item) {
      var index = this.logsitem.indexOf(item)
      if (index !== -1) {
        this.logsitem.splice(index, 1)
      }
    },
  },
  filters: {},
  mounted () {
    this.soft_code = this.$route.params.code
    if (this.soft_code !== '0') {
      this.GetInfo()
    }
  },
  created () {
    this.GetClientcode()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.upload-demo {
  float left
}
.el-progress {
  float right
  line-height 40px
  width 70%
}
</style>
